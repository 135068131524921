(function ($) {
	const $form = $('.form-validate'),
	$formGroup = $form.find('.form-group')
	
	$formGroup.each(function () {
		const label = $(this).find('label:first').text(),
		$formControl = $(this).find('.form-control[required], .custom-control-input[required], .form-img[required]')
		$formControl.attr({
			'data-parsley-required-message': label + ' harus diisi!'
		})
	})
	
	$.extend(window.Parsley.options, {
		errorClass: 'is-invalid',
		successClass: 'is-valid',
		classHandler: function(ParsleyField) {
			return ParsleyField.$element.parents('.form-group')
		},
		errorsContainer: function(ParsleyField) {
			const $formColumn = ParsleyField.$element.parents('.form-group').find('.col-lg-9')
			if ($formColumn.length > 0) {
				return $formColumn
			}
			
			return ParsleyField.$element.parents('.form-group')
		},
		errorsWrapper: '<div class="invalid-feedback"></div>',
		errorTemplate: '<div></div>'
	})
	
	$form.parsley()
	
	$form.on('submit', function () {
		if ($(this).parsley().isValid()) {
			$(this).find('.btn[type="submit"]').attr('disabled', true).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
		}
	})
})(jQuery);
